
/** Fix mobile webkit 100vh 
 *************************/
(function(){
	if(base.getWinW()<=1152) {
		var el = document.querySelector('.homekv-inner') || document.querySelector('.pagekv-inner');
		if(!el) { return; }
		var vh = base.getWinH() - 60;
		if(vh > 400) {
			el.style.height = vh + 'px';
		}
	}
})();


/** Foldable
 *************************/
$(function(){
	var state = 'foldable--expanded';
	
	$(document).on('click.foldable', '[js-foldable-head]', function(e){
		e.preventDefault();
		
		var holder = $(this).parents('[js-foldable-group]'),
			id = holder.attr('js-foldable-group'),
			head = $(this),
			body = holder.find('[js-foldable-body]');
		
		if(holder.hasClass(state)) {
			body.slideUp(300, function(){
				head.trigger('foldAfterClose', [head, body, id]);
			});
			holder.removeClass(state);
			head.trigger('foldBeforeClose', [head, body, id]);
		}
		
		else {
			body.slideDown(300, function(){
				head.trigger('foldAfterOpen', [head, body, id]);
			});
			holder.addClass(state);
			head.trigger('foldBeforeOpen', [head, body, id]);
		}
		
		// 綁定 js-foldable-group 連動收合
		if(!!id) {
			$('[js-foldable-group="'+ id +'"]').not(holder).each(function(){
				$(this).removeClass(state)
					.find('[js-foldable-body]').slideUp(300);
			});
		}
	});
	
	// Initialize
	$('.'+state+' > [js-foldable-body]').show();
});


/** Newstick 
 *************************/
$(function(){
	$(document).on('click', '.newstick-dismiss', function(e){
		$('.newstick').slideUp(200);
	});
});



/** Comfort gallery change title with slidechange
 * must comes after _swiperlist.js initialization
 *************************/
$(function(){
	$('.comfort').each(function(){
		var o = this,
			el = $('.swiper-container', o)[0];
		
		if(!!el && el.swiper) {
			el.swiper.on('slideChange', function(e){
				//console.log(el.swiper.realIndex);
				$('.comfort-item-title', o).eq(el.swiper.realIndex).addClass('active')
					.siblings().removeClass('active');
			});
		}
	});
});


/** Staff detail
 *************************/
$(function(){
	var detailHolder = $('[js-staff-detail]');
	
	if(!detailHolder[0]) { return; }
	
	$(document).on('click', '[js-staff]', function(e){
		var el = $(this);
		
		if(el.hasClass('active')) {
			close();
		}
		else {
			if(detailHolder.hasClass('detail--open')) {
				detailHolder.slideUp(300, function(){
					open(el);
					
					base.ignoreNextScrollEvent = true;
					$('html').removeClass('scrolled-up').addClass('scrolled-down');
					base.scrollTo(this, {offset: 0, always: function(){
						setTimeout(function(){
							base.ignoreNextScrollEvent = false;
						}, 100);
					}});
				});
			} else {
				open(el);
			}
			
			
			
		}
	});
	
	$(document).on('click', '.staff-dismiss', function(e){
		close();
	});
	
	var currRow;
	
	function open(staff) {
		var grid = 1,
			basis = $('.team-col').css('flex-basis'),
			selectedIndex,
			targetIndex,
			row,
			teamCol,
			total = $('.team-col').length;
		
		switch(true) {
			case /25/.test(basis): grid = 4; break;
			case /33/.test(basis): grid = 3; break;
			case /50/.test(basis): grid = 2; break;
		}
		
		selectedIndex = staff.parents('.team-col').index('.team-col') + 1;
		row = Math.ceil(selectedIndex / grid);
		targetIndex = Math.min(total, row*grid) - 1;
		
		teamCol = $('.team-col').eq(targetIndex);
		
		//console.log(selectedIndex, targetIndex);
		
		if(row == currRow) {
		}
		else {
			detailHolder.hide();
		}
		detailHolder.removeClass('detail--open');
		
		
		$('[js-staff-data1]', detailHolder).html( staff.find('[js-staff-data1]').clone() );
		$('[js-staff-data2]', detailHolder).html( staff.find('[js-staff-data2]').clone() );
		detailHolder.insertAfter(teamCol);
		
		detailHolder.slideDown(600);
		
		setTimeout(function(){
			detailHolder.addClass('detail--open');
		}, 31);
		
		staff.addClass('active');
		$('.active[js-staff]').not(staff).removeClass('active');
		
		currRow = row;
	}
	
	function close() {
		detailHolder.slideUp(600, function(){
			setTimeout(function(){
				!!base.anims && base.anims.refresh();
			}, 100);
		});
		detailHolder.removeClass('detail--open');
		$('.active[js-staff]').removeClass('active');
	}
});


/** Convert SVG file into an inline SVG element
 *************************/
/*$(function(){
	$('[js-inlineSVG]').each(function(){
		var src = $(this).attr('src');
		inlineSVG(src, this);
	});
});*/

function inlineSVG(src, el) {
    var loadXML = new XMLHttpRequest;
    function response(){
		var html = loadXML.responseText;
        if(loadXML.readyState == 4 && /<svg/.test(html)){
			if(el.className) {
				html = html.replace('<svg', '<svg class="' + el.className + '"');
			}
			//console.log(html);
			el.outerHTML = html;
			
			!!base.anims && base.anims.refresh();
        }
    }
	
    if(loadXML != null){
        loadXML.open("GET", src, true);
        loadXML.onreadystatechange = response;
        loadXML.send();
    }
}


/** SMIL morph bubble 
 *************************/
$.fn.svgPause = function(){
	return this.each(function(){
		this.pauseAnimations();
	});
};
$.fn.svgResume = function(){
	return this.each(function(){
		this.unpauseAnimations();
	});
};
$(function(){
	if(!!$('.cherimenu-pool')[0] && $('.cherimenu-pool').attr('js-d2') != 'true') { return; }

	$('path[js-d2]').each(function(){
		var d = $(this).attr('d'),
			d2 = $(this).attr('js-d2'),
			ease = $(this).attr('js-d2-ease') || '.42,0,.58,1',
			r = Math.floor(Math.random()*10)%3,
			rotate = $(this).attr('js-d2-rotate') || (r==0?'0;15;0;-15;0':r==1?'0;-14;0;14;0':'0;-10;0;12;0'),
			dur = 4;
		
		var morph = '<animate attributeName="d" dur="'+dur+'s" '+
					'calcMode="spline" keySplines="'+ease+';'+ease+'" '+
					'repeatCount="indefinite" fill="freeze" values="'+d+';'+d2+';'+d + '"/>';
			
		var scale = '<animateTransform attributeName="transform" dur="'+
					(dur*4)+'s" repeatCount="indefinite" values="'+
					rotate +'" type="rotate"/>';
			
		$(this).html(morph);
		$(this).after(scale);
		$(this).parent().html($(this).parent().html());
	});

});


/** Swap btn text when hovering
 * .btn-block only
  *************************/
$(function(){
	$('.btn-block[js-txt2]').each(function(){
		// Store original button text
		this.txt = $('>span', this).text();
		this.txt2 = $(this).attr('js-txt2');
		//console.log(this.txt, this.txt2);
		// Swap text
		$(this).on('mouseover mouseout mouseleave', function(e){
			$('>span', this).text( e.type == 'mouseover'? this.txt2 : this.txt );
		});
	});
});


/** Cherimenu
 */
$(function(){
	var cherimenu = $('.cherimenu');
	if(!cherimenu[0]) { return; }
	
	var wait;
	
	$('.cherimenu-btn')
		.on('mouseenter click', function(e){
			var btn = $(this);
			
			clearTimeout(wait)
			wait = setTimeout(function(){
				selectItem(btn)
			}, e.type == 'mouseenter' ? 200: 0);
		})
		
		.on('mouseleave', function(e){
			clearTimeout(wait)
			$(this).removeClass('over');
			resumeAll();
		});
		
	function selectItem(btn) {
		var id;
		
		btn.addClass('over').siblings().removeClass('over');
		
		id = btn.attr('js-id');
		$('.cherimenu-item[js-id="'+ id +'"]').addClass('active')
			.siblings().removeClass('active');
		$('.cherimenu-default').hide();
		
		pauseAll();
		$('svg', btn).svgResume();
	};
	
	function pauseAll() {
		$('svg', cherimenu).svgPause();
		//console.log('pauseAll');
	};
	
	function resumeAll(){
		$('svg', cherimenu).svgResume();
		//console.log('resumeAll');
	}
	
	if('IntersectionObserver' in window){
		var io = new IntersectionObserver(intersect);
		io.observe(cherimenu.get(0));
	}
	
	function intersect(entries) {
		var entry = entries[0];
		if(!entry.isIntersecting) {
			pauseAll();
			
			$('.cherimenu-default').show();
			$('.cherimenu-item').removeClass('active');
			$('.cherimenu-btn').removeClass('over');
		}
		else if(entry.isIntersecting) {
			resumeAll();
		}
	};
	
});


/** About branding animation viewport observering
 *************************/
;(function(){
	var el = document.querySelector('.brand');
	if(!el) { return; }
	
	if('IntersectionObserver' in window){
		var io = new IntersectionObserver(intersect);
		io.observe(el);
	}
	
	function intersect(entries) {
		var entry = entries[0];
		if(entry.isIntersecting) {
			document.documentElement.classList.add('brand--animate');
		}
		else {
			document.documentElement.classList.remove('brand--animate');
		}
	};
})();


/** IE 11+ position:sticky polyfill
 *************************/
$(function(){
	var el = $('.brand-playhead, .cheripg_art, .pagemenu');
	if(!!el[0] && window.navigator.msPointerEnabled) { Stickyfill.add(el); }
});


/** Postpartum pagemenu
 *************************/
$(function(){
	$('.pptm_pagemnu .pagemenu-link').on('click', function(e){
		var target = $(this).attr('href');
		var scrollOffset = -$('.masthead').height();
		
		base.waypointLocked = true;
		base.ignoreNextScrollEvent = true;
		$('html').removeClass('scrolled-up').addClass('scrolled-down');
		base.scrollTo( target, {
			offset: scrollOffset,
			always:function(){
				setTimeout(function(){
					base.waypointLocked = false;
					base.ignoreNextScrollEvent = false;
				}, 100);
			}
		});
		
		$(this).parent().siblings().removeClass('active');
		$(this).parent().addClass('active');
		
		e.preventDefault();
	});
});


/** Simple waypoint menu active state
 * using InteractionObserver
 * @version 20210803
 *************************/
base.waypointLocked = false;

$(function(){
	var btnState = 'active';
	
	var sections = $(''); // empty set
	
	$('[js-waypoint]').each(function(){
		var target = $(this).attr('js-waypoint') || $(this).attr('href');
		
		if($(target).length == 0) {
			return true;
		}
		sections = sections.add($(target).get());
	});
	
	if(sections.length == 0 || typeof window.IntersectionObserver === 'undefined') {
		return;
	}
		
	//sconsole.log(sections.length, sections);
	
	var io = new IntersectionObserver(intersect, {
		rootMargin: '-45% 0% -45% 0%' // Shrink viewport to a laser-like area to scan intersected elements
									  // https://blog.arnellebalane.com/the-intersection-observer-api-d441be0b088d
	});
	sections.each(function(){
		io.observe(this);
	});
	
	function intersect(entries) {
		if( base.waypointLocked ) {
			return;
		}
		
		entries.forEach(function(entry){
			var id = entry.target.id,
				btn = $($('[js-waypoint*="'+ id +'"]')[0] || $('[js-waypoint][href*="'+ id +'"]')[0]);
			
			if(btn.length > 0) {
				//console.log(id, entry.intersectionRatio);
				if(entry.isIntersecting) {
					//console.log('intersect IN --->', id);
					btn.addClass(btnState);
					$('[js-waypoint]').not(btn).removeClass(btnState);
				}
				
				else {
					if(btn.is('[js-waypoint-first]') && entry.boundingClientRect.y > 0  ||
					   btn.is('[js-waypoint-last]')  && entry.boundingClientRect.y < 0 ) {
						//if(/product/.test(id)) console.log('intersect OUT --->', id,  entry.boundingClientRect);
						$('[js-waypoint]').removeClass(btnState);
					}
				}
			}
		});
	};
});


/** Swiper control
 */
$(function(){
	$(document).on('click', '.swiper-control .btn-arrow-next, .swiper-control btn-arrow-prev', function(e){
		$('html').removeClass('scrolled-up').addClass('scrolled-down');
	});
});



/** Scroll cursor on swiper
 */
//$(function(){
//	$(document).on('mouseenter mousemove mousedown mouseup mouseleave', '.testist-list', function(e){
//		var cursor = $('.cursor-scroll', this),
//			x, y,
//			x0 = parseFloat(cursor.offset().left),
//			y0 = parseFloat(cursor.offset().top),
//			f = 12;
//			
//		x += (e.pageX - x0)/f;
//		y += (e.pageY - y0)/f;
//		
//		console.log(x, y);
//		
//		cursor.offset({left:x, top:y});
//	});
//});

