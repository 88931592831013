
/** Header & panel
 * 
 * [js-panel, [js-panel-btn]
 * base.openPanel
 * base.closePanel
 * 
 *************************/


/** PC navigation
 *************************/
$(function(){
	
	var	timer;
	
	// Mouse
	if(!base.isTouch) {
		
		// pc主選單 [js-panel-btn] 
		// 各自對應的 panel 子選單
		$('.navbar [js-panel-btn]').on('mouseenter mouseleave click', function(e){
			var el = this; 
			switch(e.type) {
				case 'mouseenter':
					clearTimeout(timer);
					timer = setTimeout(function(){
						
						toggleBtn(el, true);
						
						el.mouseDisabled = true;
						timer = setTimeout(function(){ el.mouseDisabled = false; }, 350);
					}, !base.activePanelId?150:1);
					break;
				
				// 避免一離開按鈕 panel就關閉
				case 'mouseleave':
					delayClosePanel();
					break;
				
				case 'click':
					if(el.mouseDisabled) { return true; } // Avoid immediate click and close an opened panel
					
					toggleBtn(el);
					break;
			}
		});
		
		$('.navbar').on('mouseleave', function(e){
		});
		
		// 避免一離開按鈕 panel就關閉
		$('[js-panel]').on('mouseenter', function(e){
			clearTimeout(timer);
		});
		
		// Mouse out 子選單
		$('[js-panel^="subnav-"]').on('mouseleave', function(e){
			delayClosePanel();
		});
		
		// Mouse over 沒有子選單的主項關閉 active panel
		/*$('.navbar-link').not('[js-panel-btn]').on('mouseenter', function(e){
			delayClosePanel();
		});*/
	}
	
	// Touch
	else {
		$('.navbar [js-panel-btn]').on('click', function(e){
			var el = this; 
			switch(e.type) {
				case 'click':
					toggleBtn(el);
					e.stopPropagation();
					e.preventDefault();
					break;
			}
		});
	}
	
	
	
	function delayClosePanel() {
		clearTimeout(timer);
		timer = setTimeout(function(){
			base.closePanel();
		}, 300);
	};
	
	function toggleBtn(el, forceOpen) {
		var btn = $(el),
			id = btn.attr('js-panel-btn');
						
		if(!btn.hasClass('opened') || forceOpen == true) { // revised @20210515
			btn.addClass('opened');
			
			// Set subnav position
			var panel = $('[js-panel="'+id+'"]');
			var left = btn.parent().position().left - (panel.outerWidth() - btn.outerWidth() - 20)*.5;
			panel.css({ left: left });
			
			base.openPanel(id, btn);
			
			panel.one('close', function(e) {
				btn.removeClass('opened');
			});
		}
		else {
			btn.removeClass('opened');
			base.closePanel();
		}
	};
	
});


/** Mobile navigation
 *************************/
$(function(){
	// Hamburger button
	$('.masthead-toggle').on('click', function(){
		base.openPanel('mbpanel');
	});
	
	// Status flag
	$('[js-panel="mbpanel"]').on('open close', function(e){
		$('html').toggleClass('mbpanel--open', e.type=='open');
	});
	
	// Close button
	$('.mbpanel-dismiss').on('click', function(e){
		base.closePanel();
	});
	
	// Animate out 
	$('[js-panel="mbpanel"]').on('close', function(e){
		$('[js-panel="mbpanel"]').addClass('animate-out');
		setTimeout(function(){
			$('[js-panel="mbpanel"]').removeClass('animate-out');
			base.closePanel();
		}, 250);
	});
	
	// Avoid touchmove body content 
	$('.mbpanel-col').blockTouchmove();
	
});


/** Click anywhere to close panel
 *************************/ 
$(function(){
	document.documentElement.addEventListener(!base.isTouch?'click':'touchend', clickAnywhere, true); // Fire event on capturing phase
	
	function clickAnywhere(e) {
		if($('.panel--active').length == 0) {
			return true;
		}
		
		//console.log('click anywhere', e.target.tagName.toUpperCase() + '.'+e.target.className.substr(10));
		
		if(!$('.masthead').find(e.target)[0] || $(e.target).is('[js-panel]')) {
			base.closePanel();
			e.stopPropagation();
			e.stopImmediatePropagation();
			return false;
		}
	}
});


/** Panel controller
 *************************/

base.activePanelId = undefined;

base.openPanel = function(id) {
	var panel = $('[js-panel="'+id+'"]');
	if(!panel[0]) { return false;}
	
	if(base.activePanelId == id) { 
		//base.closePanel();
		return;
	}
	
	base.closePanel();
	
	base.activePanelId = id;
	
	//console.log('%c openPanel id= ' + base.activePanelId, 'font-weight:700;color:#fff;background:#24BCDC'); 
	
	panel.addClass('panel--active');
	
	panel.trigger('open');
};
	
base.closePanel = function() {
	var panel = $('.panel--active[js-panel]');
	if(!panel[0]) { return false;}
	
	base.activePanelId = undefined;
	
	//console.log('%c closePanel '+ panel.attr('js-panel'), 'font-weight:700;color:#fff;background:#24BCDC'); 
	
	panel.removeClass('panel--active');
	
	panel.trigger('close');
};


	


