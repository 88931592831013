
/** 橫向選單捲軸提示
 * @version 20210509
 * 
 * 支援兩種設定做法
 * 
 * (1) Browser native overflow scrollbar -
 *
 * 	   <div js-scrollhint="holder">
 *        <div js-scrollhint="scroller">
 *         	 <div>item</div>
 *         	 <div class="active">item</div>  <!-- active item -->
 *         	 <div>item</div>
 *        </div>
 *     </div>
 *     
 * (2) 搭配 Swiper.js "freemode" -
 *
 * 	   HTML列表結構需綁定 Swiper 必要 class (.swiper-containter, .swiper-wrapper, .swiper-slide)，
 * 	   參見 js/partials/_scrollhint.js 說明
 *
 *     <div class="swiper-container" js-scrollhint="holder" js-swiper-option='{"xs":"auto", "autoHeight":false, "freeMode":true}'>
 *     	 <div class="swiper-wrapper" js-scrollhint="scroller">
 *     		<div class="swiper-slide">item</div>
 *     		<div class="swiper-slide active">item</div> <!-- active item -->
 *     		<div class="swiper-slide">item</div>
 *     		...
 *     	 </div>
 *     </div>
 *************************/
$(function(){
	if(!$('[js-scrollhint]')[0]) { return; }
	
	$('[js-scrollhint="holder"]').each(function(){
		var holder = $(this),
			scroller = $('[js-scrollhint="scroller"]', holder);
		
		if(!!$('.scrollhint', holder)[0]) { return true; }
		
		$('<div class="scrollhint"></div>').appendTo(holder);
		$('.scrollhint', holder).on('click', function(e){
			var swiper = holder.get(0).swiper;
			if(!!swiper) {
				swiper.slideNext();
			}
			else {
				scroller.animate({scrollLeft: scroller.scrollLeft() + 100}, 168);
			}
		});
		
		if(!/abs|rel/.test(holder.css('position'))) {
			holder.css('position', 'relative');
		}
	});
	
	render();
	setTimeout(render, 500);
	$(window).on('resize', $.debounce(500,render));
	
	function render() {
		$('[js-scrollhint="holder"]').each(function(){
			var holder = $(this),
				scroller = scroller = $('[js-scrollhint="scroller"]', holder),
				state = 'scrollhint--show';
				
			var sw = scroller.get(0).scrollWidth;
			var hw = holder.outerWidth() + 10;
			
			if(sw > hw && !holder.hasClass(state)) {
				holder.addClass(state);
			}
			else if(sw <= hw && holder.hasClass(state)) {
				holder.removeClass(state);
			}
			//console.log('scroll width='+sw, 'holder width'+hw);
			
			
			// 捲到 active item 定位
			var activeBtn = $('.active', scroller);
			
			if(activeBtn[0]) {
				var swiper = holder.get(0).swiper;
				// Swiper
				if(!!swiper) {
					swiper.slideTo(activeBtn.index(), 0);
				}
				// Native overflow-x:auto
				else {
					var x = Math.max(0, activeBtn.get(0).offsetLeft/* - activeBtn.outerWidth()*/);
					scroller.scrollLeft(x);
				}
			}
		});
	}
});