
/** Fancybox defaults
 * https://fancyapps.com/fancybox/3/docs/#options
 *************************/
if($.fancybox) {
	$.fancybox.defaults.hash = false; // Disable hash change
	//$.fancybox.defaults.animationEffect = "fade";
	$.fancybox.defaults.animationDuration = 168; // Duration in ms for open/close animation
	$.fancybox.defaults.buttons = [ // What buttons should appear in the top right corner.
		//"zoom",
		//"share",
		//"slideShow", 
		"fullScreen",  
		"thumbs", 
		"close"
	];
	$.fancybox.defaults.btnTpl.arrowLeft =
		'<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
			'<span class="btn-prev  btn-angle-prev"></span>'
		'</button>';
	$.fancybox.defaults.btnTpl.arrowRight =
		'<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
			'<span class="btn-next  btn-angle-next"></span>'
		'</button>';
}