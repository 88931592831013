/** Dimension helpers */
base.getPageY = function() { return window.pageYOffset || ((('clientHeight' in document.documentElement))?document.documentElement:document.body).scrollTop; };
base.getWinW = function() { return window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth||$(window).width(); };
base.getWinH = function() { var h = window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight||$(window).height(); return h; };

/** Touch device
 */
base.isTouch = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
if(base.isTouch) {
	document.documentElement.className = document.documentElement.className + ' touch';
} else {
	document.documentElement.className = document.documentElement.className + ' no-touch';
}

/** Avoid touchmove event propagating from scrollable child (overflow:scroll) to window.
 * Support touch device only
 * and can't be tested on Chrome developer simulator.
 * 
 * @see https://github.com/lazd/iNoBounce
 * @version 20191220 
 */
$.fn.blockTouchmove = function(exclude) {
	return this.each(function(){
		this.btm = this.btm || {};
		$(this)
			.off('touchstart.btm touchmove.btm')
			.on('touchstart.btm', function(e) {
				this.btm_y = e.originalEvent.touches ? e.originalEvent.touches[0].screenY : e.originalEvent.screenY;
			})
			.on('touchmove.btm', function(e) {
				if(exclude!=undefined && $(exclude).find(e.target)[0]) {
					return true;
				}
				if(this.scrollHeight > this.offsetHeight) {
					var y = e.originalEvent.touches ? e.originalEvent.touches[0].screenY : e.originalEvent.screenY;
					var atTop = (this.btm_y <= y && this.scrollTop <= 0);
					var atBottom = (this.btm_y >= y && this.scrollHeight - this.scrollTop <= /*this.offsetHeight*/$(this).innerHeight());
	
					if(atTop || atBottom) {
						e.preventDefault();
					}
				}
				else {
					e.preventDefault();
				}
			});
	});
};


/** 頁面scroll狀態
 * @version 20120803
 *************************/

// Flag to tell if the change was programmatic or by the user
base.ignoreNextScrollEvent = true;

$(function() {
	
	//$(window).on('scroll resize orientationchange load touchmove', $.debounce(5, true, update));
	$(window).on('scroll resize orientationchange load touchmove', update);
	setTimeout(update, 31);
	setTimeout(update, 300);
	
	var html = $('html'),
		isFixed = html.hasClass('header--fixed'),
		lastScrollY = 0,
		scrollThreshold;
		
	html.addClass('scrolled-down');
	
	setTimeout(function(){
		base.ignoreNextScrollEvent = false;
	}, 2000);
	
	function update() {
		var scrollY = base.getPageY(),
			vh = base.getWinH(),
			top = 10,
			bottom = html.outerHeight() - vh - 60,
			state;
		
		// Header 置頂狀態 
		if(!isFixed) {
			state = 'header--fixed';
			
			if(scrollY >= top && !html.hasClass(state)) {
				html.addClass(state);
			}
			else if(scrollY < top && html.hasClass(state)) {
				html.removeClass(state);
			}
		}
		
		// 頁面已scroll離開置頂
		state = 'leave-top';
		if(scrollY >= vh*.3) {
			html.addClass(state, scrollY >= vh*.3 );
			scrollThreshold = 25;
		}
		else {
			html.removeClass(state, scrollY >= vh*.3 );
			scrollThreshold = 6;
		}
		
		
		
		// 往上捲動才出現header
		if(!base.ignoreNextScrollEvent) {
			if(scrollY < lastScrollY - scrollThreshold || scrollY <= 0) { // 往回(往上)捲
				html.addClass('scrolled-up').removeClass('scrolled-down');
			}
			else if(scrollY > lastScrollY + scrollThreshold*.5){
				html.addClass('scrolled-down').removeClass('scrolled-up');
			}
			lastScrollY = scrollY;
		}
		
		
		// 頁面已scroll到底狀態 	
		state = 'reach-bottom';
		
		if(scrollY >= bottom && !html.hasClass(state)) {
			html.addClass(state);
			
		}
		else if(scrollY < bottom && html.hasClass(state)) {
			html.removeClass(state);
		}
	}
});


/** PC .navbar under .homekv
 *  & global header
 *************************/
$(function(){
	var pageNavbar = $('.homekv .navbar');
	
	if(!pageNavbar[0] ||/* base.getWinW() < 1152 ||*/ !$('html').hasClass('header--hidden')) {
		return;
	}
	
	$(window).on('scroll resize orientationchange load touchmove', $.debounce(5, true, update));
	
	function update() {
		var scrollY = base.getPageY(),
			top = pageNavbar.offset().top + pageNavbar.height();
		
		$('html').toggleClass('header--hidden', scrollY <= top);
	}
});


/** scrollTo element
 */
base.scrollTo = (function(anchor) {
	function _init() {
		$(document).on('click', '[js-scrollTo]', function(e) {
			scrollTo( $(this).attr('href') );
			e.preventDefault();
		});
	}
	
	function scrollTo(target, opts) {
		// Scroll to element
		var el = $(target);
		if(!el[0]) return;
		
		var y = el.offset().top,
			dist = Math.abs(y - base.getPageY()),
			opts = $.extend({
						offset: 0,
						duration: Math.round(Math.max( 500, dist*.5 )),
						easing:'swing'
					}, opts||{});
		
		// https://api.jquery.com/animate/
		$('html,body').animate({
			scrollTop: y + opts.offset
		}, opts);
	};
	
	$(_init);
	return scrollTo;
})();

// Backtop event listener
$(function() {
	$('[js-backtop]').on('click', function(e){
		base.scrollTo('html');
		e.preventDefault();
	});
});


// debug

$(function(){
	return;
	$('.masthead').on('dblclick', function(){
		$('html').toggleClass('debug');
		$('.fluid').css('border-left', '1px solid red');
		$('.fluid').css('border-right', '1px solid red');
	});
});