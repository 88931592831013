/**
 * $.fn.swiperlist
 * Swiper 客製 
 * @version 20210802
 * 
 *	在HTML結構發生處綁定屬性 js-swiper-option 來自動生成 Swiper instance 並帶入功能設定，
 *	未綁定性 js-swiper-option，則必須從 js 手動建立 Swiper instance。
 *
 *	Swiper文件 https://swiperjs.com/
 *  js使用版本 v5.4.5 ( 需留意v5.3 or 最新版本有可能不支援部分option，勿更換版本)
 *  css使用本 v4.5.0
 *	
 *	HTML範例:
 *	<div class="swiper-container"
 *		js-swiper-option='{"off":"never", "xs":2.2, "sm":3, "md":3, "lg":4, "xl":5, "paginav":"001", "prevbtn":"001", "nextbtn":"001"}'>
 *
 *		<div class="swiper-wrapper">
 *			<div class="swiper-slide">...</div>
 *			<div class="swiper-slide">...</div>
 *			<div class="swiper-slide">...</div>
 *		</div>
 *		<div class="pagination-dot" js-swiper-paginav="001">分頁按鈕</div>
 *		<div js-swiper-nextbtn="001">下一頁(unstyled)</div>
*		<div js-swiper-prevbtn="001">上一頁(unstyled)</div>
 *	</div>
 *
 *	- 需留意 JSON 合法格式，屬性名稱要雙引號""，值為字串也要雙引號""
 *  - HTML結構綁定 .swiper-container, .swiper-wrapper, .swiper-slide
 *
 *
 *	$.fn.swiperlist 自創設定：
 *	
 *		- off =>  xs|sm|md|lg|xl|never,
 *				 設定RWD寬度 依序由小至大，當寬度大到多少「就不套用Swiper」回復正常HTML；
 *		 	     例如： mobile版面需要 Swiper，pc版面不需要Swiper，可設定為 "md"，預設 "never"代表永遠作動。
 *		 	     
 * 				 另外支援直接填入 Media Query 字串，例如： (max-width:768px)
 *		 	   
 *		- xs => auto|數值, 當RWD寬度 < 433px 每分頁可視item數，支援小數點； 若為"auto"，則忽略sm,md,lg,xl。
 *		- sm => 數值, RWD寬度 >= 433px
 *		- md => 數值, RWD寬度 >= 769px
 *		- lg => 數值, RWD寬度 >= 1153px
 *		- xl => 數值, RWD寬度 >= 1536px
 *
 *	- 以下非必填
 *		- nextbtn => 指定下一頁按鈕element <div js-swiper-nextbtn="名稱"> 不可與同頁面上的其他Swiper同名稱；
 *										若未提供此參數，則自動尋找 .swiper-container 內綁定 js-swiper-nextbtn 屬性者。
 *		- prevbtn => 指定上一頁按鈕element
 *		- paginav => 指定分頁按鈕elment
 *
 *	- 其餘 Swiper內建設定皆可傳入，詳見 Swiper文件，
 *	  例如：
 *		- loop
 *		- autoplay
 *		- autoHeight
 *		- freeMode
 *	
 *************************/
$.fn.swiperlist = function(instanceOptions) {
	if(!window.Swiper) { return this; } //@20210628
	
	// 由 js 建立的instance 帶入 options
	var instanceOptions = instanceOptions || {};
	
	// Defaults options
	var basicOptions  = {
		// https://swiperjs.com/swiper-api#parameters
		init			: true,
		autoHeight		: true,
		loop			: false,
		slidesPerView	: 1,
		spaceBetween	: 0,
		threshold		: 3,
		autoplay		: { disableOnInteraction: false },
		pagination		: {
			el: null,
			type: 'bullets',
			clickable: true,
			renderBullet: function (index, className) {
				return '<span class="' + className + '">' + (index + 1) + '</span>';
			}
		},
		watchOverflow	: false, //When enabled Swiper will be disabled and hide navigation buttons on case there are not enough slides for sliding.
		observer		: true,
		observeParents	: true,
		preventInteractionOnTransition: false
	};
	
	
	var io; 
	if('IntersectionObserver' in window){
		io = new IntersectionObserver(intersect);
	}
	
	return this.each(function() {
		
		var o = this;
		
		if(!!o.swiper) { return true; }
		
		
		// HTML 綁定 js-swiper-option 傳入的 options 
		var paramData = $(o).attr('js-swiper-option');
		var paramOptions = { off: instanceOptions.off || 'never' };
		
		try {
			paramData = JSON.parse(paramData);
		} catch (e) {}
		
		if(!!paramData) {
			
			// 複製 js-swiper-option
			$.extend(true, paramOptions, paramData);
			
			// 處理 js-swiper-option
			
			// xs - 決定每頁可視item數 (slidesPerView, slidesPerGroup)
			// xs 為'auto'
			if(paramData.xs == 'auto') {
				$.extend(true, paramOptions, { slidesPerView:'auto' });
			}
			
			// xs,sm,md,lg,xl 依 RWD 寬度帶入每個 breakpoints.slidesPerView 數值
			else {
				// 若沒有傳入 xs 則取 slidePerView
				var num = parseFloat(paramData.xs || paramData.slidesPerView || basicOptions.slidesPerView);
				
				$.extend(true, paramOptions, { slidesPerView: num, slidesPerGroup: Math.floor(num) });
				
				
				num = parseFloat(paramData.sm || num);
				if(!!num) {
					$.extend(true, paramOptions, { breakpoints: { 433: { slidesPerView: num, slidesPerGroup: Math.floor(num) }}});
				}
				num = parseFloat(paramData.md || num);
				if(!!num) {
					$.extend(true, paramOptions, { breakpoints: { 769: { slidesPerView: num, slidesPerGroup: Math.floor(num) }}});
				}
				num = parseFloat(paramData.lg || num);
				if(!!num) {
					$.extend(true, paramOptions, { breakpoints: { 1153: { slidesPerView: num, slidesPerGroup: Math.floor(num) }}});
				}
				num = parseFloat(paramData.xl || num);
				if(!!num) {
					$.extend(true, paramOptions, { breakpoints: { 1537: { slidesPerView: num, slidesPerGroup: Math.floor(num) }}});
				}
			}
			
			
			// paginav 分頁鈕
			$.extend(true, paramOptions, { pagination: {
				el: $('[js-swiper-paginav]', o)[0] || (!!paramData.paginav ? $('[js-swiper-paginav='+paramData.paginav+']')[0] : null)
			}});
			if(!!paramData.pagination) {
				$.extend(true, paramOptions, { pagination: paramData.pagination });
			}
			
			// prevbtn, nextbtn 上下頁鈕
			$.extend(true, paramOptions, { navigation: {
				prevEl: $('[js-swiper-prevbtn]', o)[0] || (!!paramData.prevbtn ? $('[js-swiper-prevbtn='+paramData.prevbtn+']')[0] : null),
				nextEl: $('[js-swiper-nextbtn]', o)[0] || (!!paramData.nextbtn ? $('[js-swiper-nextbtn='+paramData.nextbtn+']')[0] : null)
			}});
		}
		
		// 最終Swiper instance要吃的 options
		//var options = $.extend(true, {}, basicOptions, instanceOptions, paramOptions);
		paramOptions = $.extend(true, {}, basicOptions, instanceOptions, paramOptions);
		
		// autoplay 輪播
		if(!paramOptions.autoplay || !paramOptions.autoplay.delay || $('.swiper-slide', o).length < 2) {//@20210628
			$.extend(true, paramOptions, {autoplay: false});
		}
		
		if($('.swiper-slide', o).length < 2) {//@20210628
			$.extend(true, paramOptions, {loop: false});
		} 
		
		//console.log('%c '+o.className.replace(/swiper-container/g,'') + ' %c' + JSON.stringify(paramOptions), 'font-weight:700;color:#fff;background:#24BCDC', 'color:#777;margin-left:20px');
		
		function init() {
			var swiper = new Swiper(o, paramOptions);
			
			if(!!io && o.swiper.autoplay !== false) {
				io.unobserve(o);
				io.observe(o);
			}
			
			// Page number @20210801
			if(!!paramData.fraction) {
				var el = $($('[js-swiper-fraction]', o)[0] || $('[js-swiper-fraction='+paramData.fraction+']')[0]);
				
				if(el.length > 0) {
					
					var curr = 1,
						total = swiper.slides.length;
					
					if(total > 1) {
						
						if(paramOptions.loop) {
							total = total - 2;
						}
						swiper.off('slideChange').on('slideChange', function(e){
							
							if(paramOptions.loop) {
								curr = swiper.realIndex + 1;
							}
							else {
								curr = swiper.activeIndex + 1;
							}
							el.find('.fraction-current').text(curr);
							
							//console.log('slideChange', swiper, curr + '/' + total);
						});
					}
					
					el.find('.fraction-current').text(curr);
					el.find('.fraction-total').text(total);
					
					el.css('opacity', 1);
				}
			} 
			
		};
		
		init();
		offAt(paramOptions.off, o, init);
	});
	
	function intersect(entries) {
		entries.forEach(function(entry) {
			var o = entry.target;
			if(!entry.isIntersecting && !!o.swiper && o.swiper.autoplay !== false && o.swiper.autoplay.running) {
				o.swiper.autoplay.stop();
				o.swiper.paused = true;
				//console.log('stopped autoplay', o);
			}
			else if(entry.isIntersecting && !!o.swiper && o.swiper.paused == true) {
				o.swiper.autoplay.start();
				//console.log('restart autoplay', o);	
			}
		});
	};
	
	/** 依據螢幕寬度移除 Swiper 或重新啟動
	 */
	function offAt(off, o, reInit) {
	   var media;
	   
		switch(off) {
			case 'sm':
				media = window.matchMedia('(min-width:433px)');
				break;
			case 'md':
				media = window.matchMedia('(min-width:769px)');
				break;
			case 'lg':
				media = window.matchMedia('(min-width:1153px)');
				break;
			case 'xl':
				media = window.matchMedia('(min-width:1537px)');
				break;
			default:
				media = window.matchMedia(off);
				break;
		}
		//console.log('off', off, o.className.replace(/swiper-container/g,''));
		if(off == 'never' || off=='xs' || !media) { return; }
		
		media.addListener(mediaCheck);	
		mediaCheck(media);
		
		function mediaCheck(e){
			if(e.matches && !!o.swiper){
				o.swiper.destroy(true, true);
				o.swiper = undefined;
				
				$(o).removeClass('swiper-container').addClass('swiper-container-off');
				$('.swiper-wrapper', o).removeClass('swiper-wrapper').addClass('swiper-wrapper-off');
				$('.swiper-slide', o).removeClass('swiper-slide').addClass('swiper-slide-off');
			}
			else{
				if(!o.swiper) {
					
					$(o).removeClass('swiper-container-off').addClass('swiper-container');
					$('.swiper-wrapper-off', o).removeClass('swiper-wrapper-off').addClass('swiper-wrapper');
					$('.swiper-slide-off', o).removeClass('swiper-slide-off').addClass('swiper-slide');
				
					reInit();
				}
			}
		}
	}
};

$.fn.swiperUpdate = function() {
	if(!window.Swiper) { return this; }
	
	var swiper = this.get(0).swiper;
	if(!!swiper) {
		swiper.updateAutoHeight();
		swiper.update();
	}
	return this;
};

$.fn.swiperRewind = function() {
	if(!window.Swiper) { return this; }
	
	var swiper = this.get(0).swiper;
	if(!!swiper) {
		swiper.update();
		swiper.slideTo(0);
	}
	return this;
};

// js-swiper-option 初始化
$(function(){
	if(!window.Swiper) { return; }
	
	// 全站套用
	$('[js-swiper-option]').swiperlist();	
	
	// or 個別套用
	//$('.XXXXX').swiperlist();
	//$('.XXXXX').swiperlist({ at: 'md', slidesPerView: 1 });
});
